<template>
  <v-row class="d-flex justify-center mt-16">
    <v-col cols="12" md="5">
      <v-form @submit="submit">
        <v-card class="card-shadow">
          <v-card-text class="pa-12 text-center font-size-root">
            <small class="text-muted">Accedi usando la tua matricola</small>
            <v-text-field
              hide-details
              outlined
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              placeholder="Matricola"
              autocomplete="matricola"
              v-model="matricola"
              class="
                font-size-input
                placeholder-lighter
                text-color-light
                input-alternative input-focused-alternative input-icon
                mt-6
                mb-4
              "
            >
              <template slot="prepend-inner">
                <v-icon color="#adb5bd" size=".875rem">far fa-id-card</v-icon>
              </template>
            </v-text-field>

            <v-text-field
              hide-details
              outlined
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              placeholder="Password"
              autocomplete="password"
              type="password"
              v-model="password"
              class="
                font-size-input
                placeholder-lighter
                text-color-light
                input-alternative input-focused-alternative input-icon
                mb-6
              "
            >
              <template slot="prepend-inner">
                <v-icon color="#adb5bd" size=".875rem"
                  >ni-lock-circle-open</v-icon
                >
              </template>
            </v-text-field>

            <v-checkbox
              v-model="checkbox"
              color="primary"
              :ripple="false"
              class="ma-0 checkbox-custom checkbox-thinner"
              hide-details
            >
              <template v-slot:label>
                <span class="text-muted text-body-2 ls-0">Resta connesso</span>
              </template>
            </v-checkbox>

            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-600
                text-capitalize
                btn-primary
                py-3
                px-6
                rounded-sm
                my-6
              "
              color="primary"
              type="submit"
              >Entra</v-btn
            >
          </v-card-text>
        </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>
<script>
import apiLogin from "@/js/pages/login";
import apiUtente from "@/js/pages/utenti";

export default {
  name: "login",
  data() {
    return {
      checkbox: false,
      loading: false,
      matricola: "",
      password: "",
    };
  },
  methods: {
    async submit(e) {
      if (!this.loading) {
        e.preventDefault();
        this.loading = true;
        let data = {
          matricola: this.matricola,
          password: this.password,
        };
        let token = await apiLogin.login(data);
        if (token) {
          let expires = 30;
          if (this.rememberMe) expires = 365;
          await this.generalFunction.setCookie(
            "token_goldencar",
            token,
            expires
          );
          this.EventBus.$emit('login');
          let ruolo = await apiUtente.getRuolo(token);
          if (ruolo === 'Amministratore') this.$router.push("/").catch((e) => {});
          else if (ruolo === 'Operatore') this.$router.push("/listProgettiOperatore").catch((e) => {});
          else this.EventBus.$emit("showAlert", ["Errore Utente", "error"]);
        } else {
          this.EventBus.$emit("showAlert", ["Credenziali Errate", "error"]);
        }
        this.loading = false;
      }
    },
  },
};
</script>
